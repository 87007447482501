import { createSlice } from '@reduxjs/toolkit'
import { registerUser, sendOTP, userEmailLogin, userPhoneLogin, verifyOTP } from './authActions'

const userToken = localStorage && localStorage.getItem('antcloud-token')
    ? localStorage.getItem('antcloud-token')
    : null;

const userRefreshToken = localStorage && localStorage.getItem('antcloud-refresh')
    ? localStorage.getItem('antcloud-refresh')
    : null

const initialState = {
    loading: false,
    userInfo: false,
    userToken,
    userRefreshToken,
    error: false,
    success: false,
    loggedIn: false,
    message: false,
    otpVerifiedMessage: false,
    otpVerifiedError: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetErrorAndMessage: (state) => {
           state.error = false;
           state.message = false;
           state.success = false;
           state.otpVerifiedError = false;
           state.otpVerifiedMessage = false;
        },

        refreshTokens: (state, { payload }) => {
            localStorage.setItem("antcloud-token", payload.accessToken)
            localStorage.setItem("antcloud-refresh", payload.refreshToken)
            state.userToken = payload.accessToken;
            state.userRefreshToken = payload.refreshToken;
        },

        setUserPlan: (state, { payload }) => {
            state.userInfo = payload;
        },

        logout: (state) => {
            localStorage.clear();
            state.loggedIn = false;
            state.userInfo = false;
            state.userRefreshToken = false;
            state.userToken = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sendOTP.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(sendOTP.fulfilled, (state, { payload }) => {
            state.loading = false
            state.message = payload
            state.success = true 
        })

        builder.addCase(sendOTP.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload
        })

        builder.addCase(verifyOTP.pending, (state) => {
            state.loading = true
            state.otpVerifiedError = false
        })

        builder.addCase(verifyOTP.fulfilled, (state) => {
            state.loading = false
            state.otpVerifiedMessage = true
        })

        builder.addCase(verifyOTP.rejected, (state, { payload }) => {
            state.loading = false;
            state.otpVerifiedError = payload.message;
        })

        builder.addCase(registerUser.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(registerUser.fulfilled, (state, { payload }) => {
            const { token, refreshToken, ...newPayload} = payload
            state.loading = false;
            state.userInfo = newPayload;
            state.userToken = payload.token;
            state.userRefreshToken = payload.refreshToken;
            state.loggedIn = true;
            state.success = true ;
        })

        builder.addCase(registerUser.rejected, (state, { payload }) => {
            // console.log(payload)
            state.loading = false
            state.error = payload
        })

        builder.addCase(userEmailLogin.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(userEmailLogin.fulfilled, (state, { payload }) => {
            const { token, refreshToken, ...newPayload} = payload
            state.loading = false
            state.userInfo = newPayload
            state.userToken = payload.token
            state.userRefreshToken = payload.refreshToken;
            state.success = true;
            state.loggedIn = true;
        })

        builder.addCase(userEmailLogin.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload
        })

        builder.addCase(userPhoneLogin.pending, (state) => {
            state.loading = true
            state.error = false
        })

        builder.addCase(userPhoneLogin.fulfilled, (state, { payload }) => {
            const { token, refreshToken, ...newPayload} = payload
            state.loading = false
            state.userInfo = newPayload
            state.userToken = payload.token
            state.userRefreshToken = payload.refreshToken;
            state.success = true;
            state.loggedIn = true;
        })

        builder.addCase(userPhoneLogin.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload
        })
    },
});

export const { resetErrorAndMessage, refreshTokens, setUserPlan, logout } = authSlice.actions;
export default authSlice.reducer