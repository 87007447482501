import React, { useRef } from "react"
import { Link } from "react-router-dom"

export default function PrivacyPolicy() {
    return (
        <>
            <section style={{ padding: "3rem 0" }} class="help-section writter-artical-details help-section-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading-innerBanner">
                                <h2 class="section-heading text-center">
                                    <span> Privacy Policy</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div style={{ border: "1px solid #ddd", padding: "4rem 60px 12px", marginTop: "3rem" }} class="help-box">
                                <div class="help-box-inner2 mt-0">
                                    <div class="single-artical">
                                        <div class="content">
                                            <div class="inner-content">
                                                <h4 class="title">
                                                    1. GENERAL
                                                </h4>
                                                <p class="text">
                                                    Antcloud is committed to protecting any data that we collect concerning you. By using our services you agree to the use of the data that we collect in accordance with this Privacy Policy. All possible measures, steps &amp; procedures are adopted to stay-put towards the India Data Protection Legislation.
                                                </p>
                                                <h4 class="title">
                                                    2. Information Collected
                                                </h4>
                                                <p class="text">
                                                    We do not provide the information collected from you to any third party for any reason what-so-ever. However, we do have to co-operate with law enforcement agencies authorized by the government if they do ask us about the contact details we have for you.
                                                </p>
                                                <h4 class="title">
                                                    3. Information Use
                                                </h4>
                                                <p class="text">
                                                    This information is used for billing and to provide service and support to our customers. We may also study this information to determine our customers needs and provide support for our customers. All reasonable precautions are taken to prevent unauthorised access to this information. This safeguard may require you to provide additional forms of identity should you wish to obtain information about your account details. Antcloud may email its monthly newsletter to the primary contact e-mail on file, but customers are able to opt out of this newsletter at any time.
                                                </p>
                                                <h4 class="title">
                                                    4. Cookies
                                                </h4>
                                                <p class="text">
                                                    Your Internet browser has the in-built facility for storing small text files – “cookies” – that hold information which allows a website to recognize your account. We use cookies to save your preferences and login information, and provide personalized functionality. You can reject cookies by changing your browser settings, but be aware that this will disable some of the functionality on the Antcloud.tech website.
                                                </p>
                                                <h4 class="title">
                                                    5. Log Files
                                                </h4>
                                                <p class="text">
                                                    We use IP addresses to analyze trends, administer our site and servers, track access, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information. It is possible that personal information about a customer may be included in the log files due to the normal functions of IP addresses and Web browsing.
                                                </p>
                                                <h4 class="title">
                                                    6. Disclosing Information
                                                </h4>
                                                <p class="text">
                                                    We do not disclose any personal information obtained about you from this website to third parties. We may use the information to keep in contact with you and inform you of developments associated with our business. You will be given the opportunity to opt out from any mailing list or similar device.
                                                </p>
                                                <h4 class="title">
                                                    7. Electronic Communication
                                                </h4>
                                                <p class="text">
                                                    You will be asked to provide your email address to us during the signup process to be able to communicate with you and we also ask you to provide provide us with your actual postal address, telephone number &amp; fax number in order to check and verify the distance between the address mentioned &amp; the location of signup. This is an important part of the verification process we follow for each signup which we receive. Normally we send all our notifications through emails which we have for your account on our record.
                                                </p>
                                                <h4 class="title">
                                                    8. Duration
                                                </h4>
                                                <p class="text">
                                                    The information so provided by you during registration is kept in our records till the time you are our active customer.
                                                </p>
                                                <h4 class="title">
                                                    9. Contacting Us
                                                </h4>
                                                <p class="text">
                                                    If you have any questions about our Privacy Policy, or if you want to know what information we have collected about you, please email us at <a href="mailto:support@antcloud.co"> support@antcloud.co</a>; You can also correct any factual errors in that information or require us to remove your details from any list under our control.
                                                </p>
                                                <h4 class="title">
                                                    10. llegal activities
                                                </h4>
                                                <p class="text">
                                                    <li>Illegal activities, to include; unauthorized distribution or copying copyrighted software, violation of US export restrictions, harassment, fraud, trafficking in obscene material, and other illegal activities will be reported to all regulatory, administrative and/or governmental authorities. Antcloud.co reserves the right to report all such activities.
                                                    </li>
                                                    <li>You restricted from removing, modifying or obscuring any copyright, trademark or other proprietary rights notices that are contained in or on the Licensed Products.
                                                    </li>
                                                    <li>You are prohibited from reverse engineering, decompiling, or disassembling the Licensed Products, except to the extent that such activity is expressly permitted by applicable law.
                                                    </li>
                                                </p>
                                                <h4 class="title">
                                                    11. Cancellation and Refunds
                                                </h4>
                                                <p class="text">
                                                    <li>Antcloud.co reserves the right to cancel the account at any time.
                                                    </li>
                                                    <li>Customers may cancel at any time. Antcloud.co does not give any money back guarantee for its services
                                                    </li>
                                                    <li>There are no refunds or money back guarantee on dedicated servers, including setup fees that may have been charged.
                                                    </li>
                                                    <li>After the initial 7 days of service, each customer is committed to the remaining time on the service term they purchased and no refunds will be given. Antcloud does not require that you finish each term and you are free to close your account at anytime. However, Antcloud will not return for unused service. For example, if you have an account that you are paying for monthly and you decide to close it a few days after your last payment, you will not be issued any refund, whether in full or pro-rated. By using Antcloud, you understand and agree to this billing term. You also understand that while no refund will be issued
                                                    </li>
                                                </p>
                                                <h4 class="title">
                                                    12. Price Change
                                                </h4>
                                                <p class="text">
                                                    <li>The amount you pay for services will not increase from the date of purchase until the end of the service agreement, unless upgrades in service are requested. Antcloud reserves the right to change prices listed on Antcloud.co, and the right to increase the amount of resources given to plans at any time.
                                                    </li>
                                                    <li>Renewal of service agreements are subject to potential price increases due to software licensing increases, equipment &amp; dependent service cost increases. Customer will be made aware of increases before time of renewal and required to approve price escalations or terminate service agreement.
                                                    </li>
                                                </p>
                                                <h4 class="title">
                                                    13. Software Licensing
                                                </h4>
                                                <p class="text">
                                                    <li>Any software licenses obtained on Customer's behalf (such as licenses from the Microsoft Service Provider License Agreement "SPLA") are subject to price increases to match any increases made by the software manufacturer.
                                                    </li>
                                                </p>
                                                <h4 class="title">
                                                    14. Taxes
                                                </h4>
                                                <p class="text">
                                                    <li>Antcloud shall not be liable for any taxes or other fees to be paid in accordance with or related to purchase made from the customer or Antcloud servers. Customer also agrees to take full responsibility for all taxes and fees of any nature associated with any such products sold.
                                                    </li>
                                                </p>
                                                <h4 class="title">
                                                    15. Warranties and Disclaimer
                                                </h4>
                                                <p class="text">
                                                    <li>Customer expressly acknowledges and agrees that all use of the services is at customer's sole risk. Antcloud will not be responsible for any damages your business may suffer. Antcloud makes no warranties of any kind, expressed or implied for services provided. Antcloud disclaims any warranty or merchantability or fitness for a particular purpose. This includes loss of data resulting from delays, no deliveries, wrong delivery, and any and all service interruptions caused by Antcloud and its employees.
                                                    </li>
                                                    <li>For those services for which Antcloud offers a system of credits or rebates for services interruptions, regardless of cause, such credits or rebates shall be Customer's sole remedy therefore.
                                                    </li>
                                                    <li>For direct, proven damages arising out of its performance or failure to perform hereunder, Antcloud's liability shall be limited to an amount equivalent to the charges actually paid by Customer during the period, which such damages occur.
                                                    </li>
                                                    <li>Notwithstanding the foregoing, neither Antcloud nor its affiliates, officers, directors, employees or agents shall be liable to Customer or to any third party for any direct, consequential, incidental, exemplary, or punitive losses or damages, including, without limitation, lost profits or data, regardless of the cause thereof, even if Antcloud is advised of the possibility of such loss.
                                                    </li>
                                                </p>
                                                <h4 class="title">
                                                    16. Indemnification
                                                </h4>
                                                <p class="text">

                                                    <li>Customer agrees that it shall defend, indemnify, save and hold Antcloud.co harmless from any and all demands, liabilities, losses, costs and claims, including reasonable attorney's fees asserted against Antcloud, its agents, its customers, officers and employees, that may arise or result from any service provided or performed or agreed to be performed or any product sold by customer, its agents, employees or assigns. Customer agrees to defend, indemnify and hold harmless Antcloud against liabilities arising out of; (1) any injury to person or property caused by any products sold or otherwise distributed in connection with Antcloud; (2) any material supplied by customer infringing or allegedly infringing on the proprietary rights of a third party; (3) copyright infringement and (4) any defective products sold to customers from Antcloud’s server.
                                                    </li>
                                                    <li>You agree that Antcloud's maximum liability to you under this agreement for all damages, losses, costs and causes of actions from any and all claims (whether in contract, tort or other legal theory) shall be limited to the lesser of (a) the total amount of service fees actually paid by you to Antcloud for the three month period immediately preceding the date on which the damage or loss occurred or the cause of action arose, (b) proven direct damages The terms of this Indemnification Section shall survive any termination of this Agreement.
                                                    </li>

                                                </p>
                                                <h4 class="title">
                                                    17. Force Majeure
                                                </h4>
                                                <p class="text">
                                                    Except for the obligation to make payments, neither party will be liable for any failure or delay in its performance under this Agreement due to any cause beyond its reasonable control, including, but not limited to, acts of war, acts of God, earthquake, flood, embargo, riot, sabotage, labor shortage or dispute, governmental act or failure of the Internet (not resulting from the actions or inaction´s of Antcloud), provided that the delayed party: (a) gives the other party prompt notice of such cause, and (b) uses its reasonable commercial efforts to promptly correct such failure or delay in performance. If Antcloud is unable to provide Service(s) for a period of thirty (30) consecutive days as a result of a continuing force majeure event, the Customer may cancel the Service(s), but there shall be no liability on the part of Antcloud.
                                                </p>
                                                <h4 class="title">
                                                    18. Assignment
                                                </h4>
                                                <p class="text">
                                                    The Customer may not assign its rights or delegate its duties under this Agreement either in whole or in part without the prior written consent of Antcloud, and any attempted assignment or delegation without such consent will be void. Antcloud may assign this Agreement in whole or part. Antcloud also may delegate the performance of certain Services to third parties, including Affiliates. This Agreement will bind and inure to the benefit of each party's successors and permitted assigns.
                                                </p>
                                                <h4 class="title">
                                                    19. Severability
                                                </h4>
                                                <p class="text">
                                                    In the event any provision of this Agreement is held by a tribunal of competent jurisdiction to be contrary to the law, the remaining provisions of this Agreement will remain in full force and effect. The waiver of any breach or default of this Agreement will not constitute a waiver of any subsequent breach or default, and will not act to amend or negate the rights of the waiving party.
                                                </p>
                                                <h4 class="title">
                                                    20. Relationship of Parties
                                                </h4>
                                                <p class="text">
                                                    This Agreement will not establish any relationship of partnership, joint venture, employment, franchise or agency between Antcloud and the Customer. Neither Antcloud nor the Customer will have the power to bind the other or incur obligations on the other's behalf without the other's prior written consent, except as otherwise expressly provided herein.
                                                </p>
                                                <h4 class="title">
                                                    21. Notices
                                                </h4>
                                                <p class="text">
                                                    It is your responsibility to provide accurate and complete account and contact information, including a valid e-mail address, to Antcloud during the Sign-up Process. It is also your responsibility to inform Antcloud promptly of any changes to your account or contact information. Antcloud has no responsibility for communications that are misdirected as a result of your failure to provide Antcloud with updated contact information or as a result of the suspension or termination of your Services.
                                                </p>
                                                <p class="text">
                                                    Antcloud may provide notice to you required by this Agreement via e-mail at the address provided by you. You agree that notice to you at this address is deemed sufficient regardless of your receipt of such email. You must provide all notices to Antcloud required in writing to Antcloud, KHASRA NO. 522/1 VILLAGE JONAPUR NEW DELHI South Delhi DL 110030 IN or as changed by Antcloud via its on-line amendment of this Agreement.
                                                </p>
                                                <h4 className="title">
                                                    22. Maintenance
                                                </h4>
                                                <p className="text">
                                                    From time to time, the website or platform will undergo system maintenance. This can happen without any prior notice, & in special cases, Antcloud can notify users of the same beforehand by putting up a notification on the website or platform or any of our social media handles.
                                                </p>
                                                <h4 class="title">
                                                    23. Governing Law &amp; Choice of Venue
                                                </h4>
                                                <p class="text">
                                                    This agreement and rights of the Parties hereunder shall be governed by and interpreted in accordance with the laws of the India, excluding its laws relating to conflict laws. The Parties agree that any appropriate state or district court located in New Delhi, India shall have exclusive jurisdiction over any case or controversy arising hereunder and shall be the proper forum in which to adjudicate such as case or controversy.
                                                </p>
                                                <h4 class="title">
                                                    24. Changes to the Polices and Terms of Service
                                                </h4>
                                                <p class="text">
                                                    <li>Antcloud reserves the right to revise its policies at any time without notice.</li>
                                                </p>
                                                <h4 class="title">
                                                    25. Delete your Account Information
                                                </h4>
                                                <p class="text">
                                                    You can contact Ant Cloud Support Staff to help delete your information.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}