import React, { useRef } from "react"
import LatestNews from "../Components/LatestNews"
import Parallax from "../Components/Parallax"
import { Link } from 'react-router-dom';

export default function Download() {

    return (
        <>
            {/* Banner Section */}
            <section className="inner-banner-sec support-banner-sec" >
                <div className="" data-aos="fade-up" data-aos-delay="300">
                    <div className="container custom-container" >
                        <div className="banner-caption text-center">
                            <h1 className="main-heading main-heading-md">Download</h1>
                            {/* <p className="subHeading-text">Duis at volutpat orci facilisis risus nunc consequat dui volutpat et. </p> */}
                        </div>
                    </div>
                </div>
            </section>

            {/* Three Box Section */}
            <section className="points-box-sec downloadsP-content">
                <div className="container custom-container">
                    <h2 className="section-heading" data-aos="fade-up" data-aos-delay="300">Where Can You Play?</h2>
                    <p className="subHeading-text" data-aos="fade-up" data-aos-delay="320">Antcloud is now available via all the below mentioned devices!</p>
                    <div className="row">
                        {/* <div className="col-lg-4 points-box image-parallax">
                        <div className="points-boxInn" data-aos="fade-up" data-aos-delay="300">
                                <div className="points-box-img">
                                    <i className="fas fa-globe animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored">BROWSER</h3>
                                    <h3 className="box-heading box-heading-sm">Play on your browser!</h3>
                                    <p>Chrome or Edge recommended </p>
                                    <a href="#" className="btn-comm">Choose <i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div> */}

                         <Parallax>
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="300">
                                <div className="points-box-img">
                                    <i className="fas fa-globe animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored">BROWSER</h3>
                                    <h3 className="box-heading box-heading-sm">Play on your browser!</h3>
                                    <p>Chrome or Edge recommended </p>
                                    <Link to="/signin" className="btn-comm">Start Playing <i className="fas fa-chevron-right"></i></Link>
                                </div>
                            </div>
                        </Parallax>

                        <Parallax>
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="320">
                                <div className="points-box-img">
                                    <i className="fas fa-mobile-alt animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored">MOBILE APP</h3>
                                    <h3 className="box-heading box-heading-sm" style={{ paddingBottom: "2.5rem" }}>Play on your Android smartphone!</h3>
                                    {/* <p className="text-highlights">iOS suppport coming soon!</p> */}
                                    {/* <p style={{ marginTop: "1rem" }} className="text-highlights">Coming soon!</p> */}
                                    {/* <button style={{ backgroundColor: "transparent" }} className="btn-comm coming-soon-btn text-highlights">Coming Soon <i className="fas fa-chevron-right"></i></button> */}
                                    <a href="https://play.google.com/store/apps/details?id=com.antcloud.app" className="btn-comm">Start Playing <i className="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </Parallax>

                       <Parallax>
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="340">
                                <div className="points-box-img">
                                    <i className="fab fa-windows animated-icon"></i>
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading box-heading-colored"> WINDOWS APP</h3>
                                    <h3 className="box-heading box-heading-sm" style={{ paddingBottom: "2.5rem" }}>Download our app!</h3>
                                    <button style={{ backgroundColor: "transparent", '&:hover': { color: "#45d4b8" } }} className="btn-comm coming-soon-btn text-highlights">Coming Soon <i className="fas fa-chevron-right"></i></button>
                                    {/* <p>Windows 10 & above</p> */}
                                    {/* <p className="text-highlights">Coming soon!</p> */}
                                    {/* <a href="#" className="btn-comm">Choose <i className="fas fa-chevron-right"></i></a> */}
                                </div>
                            </div>
                        </Parallax>
                    </div>



                    <div className="notes-sec mt-5" data-aos="fade-up" data-aos-delay="300">
                        {/* <p>
                            Please make sure your system has the <a href="#">recomended specs as mentioned here</a>.
                        </p> */}
                        <p style={{ marginBottom: 0, paddingBottom: "1rem" }}>
                            By using our platform & our applications, you are agreeing to it's <Link to="/privacyPolicy">End User License Agreement</Link>.
                        </p>
                    </div>
                </div>
            </section>

            {/* Latest News */}

            <LatestNews />
        </>
    )
}