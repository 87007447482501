import React, { useEffect, useRef, useState } from "react"
import videoImg from '../images/videoImg.webp';
import fourkLg from '../images/4k-lg.webp'
import pcImg from '../images/pc.webp'
import galleryLgImg from '../images/gallery-lg.webp'
import CheckIcon from "@mui/icons-material/Check";
// import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// import joinImg from '../images/join-img.webp'
// import shapeImg from '../images/shape.webp'
import joinUsVideo from '../videos/join-us.mp4';
import LatestNews from "../Components/LatestNews";
import Parallax from "../Components/Parallax";
import { useDispatch, useSelector } from "react-redux";
import { logout, refreshTokens } from "../features/auth/authSlice";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import Crousal from "../Components/Crousal";

export default function Home() {
    const [qrAuth, setQrAuth] = useState(false);
    const [qrAuthResponse, setQrAuthResponse] = useState(false);
    const [qrAuthResponseLoading, setQrAuthResponseLoading] = useState(false)
    const [qrAuthModalMessage, setQrAuthModalMessage] = useState("")
    const [tvCode, setTvCode] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userToken, userRefreshToken, loggedIn } = useSelector(state => state.auth);

    const handleQRLoginAccept = (token, refresh) => {
        setQrAuth(false)
        setQrAuthResponseLoading(true);
        // this.setState({ qrAuth: false, qrAuthResponseLoading: true })
        fetch(
            `https://api.antcloud.co/api/tvcodes/verify`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`
                },
                body: JSON.stringify({
                    tvCode: tvCode
                }),
            }
        )
            .then(async res => {
                if (res.status === 401) {
                    fetch('https://api.antcloud.co/api/users/refresh',
                        {
                            headers: {
                                "refresh": `JWT ${refresh}`
                            }
                        }).then((resp) => resp.json()).then(data => {
                            if (data.message.includes("Forbidden Request!") || data.message.includes("Re-authentication needed!")) {
                                dispatch(logout());
                                navigate("/signin");
                            } else if (data.message.includes("New Access Pairs Generated")) {
                                dispatch(refreshTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken }));
                                handleQRLoginAccept(data.accessToken, data.refreshToken);
                            }
                        })
                }
                const response = await res.json();
                let { code, message } = response;
                if (message === "Invalid Token") {
                    dispatch(logout());
                    navigate("/signin");
                } else if (message === "Token Expired") {
                    fetch('https://api.antcloud.co/api/users/refresh',
                        {
                            headers: {
                                "refresh": `JWT ${refresh}`
                            }
                        }).then((resp) => resp.json()).then(data => {
                            if (data.message.includes("Forbidden Request!") || data.message.includes("Re-authentication needed!")) {
                                dispatch(logout());
                                navigate("/signin");
                            } else if (data.message.includes("New Access Pairs Generated")) {
                                dispatch(refreshTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken }));
                                handleQRLoginAccept(data.accessToken, data.refreshToken);
                            }
                        })
                } else {
                    if (code == "success") {
                        setQrAuthResponse(true)
                        setQrAuthResponseLoading(false)
                        setQrAuthModalMessage("You'll now be automatically logged in on your Samsung TV! Wait for a few seconds!")
                        // this.setState({ qrAuthResponse: true, qrAuthResponseLoading: false });
                        // this.setState({ qrAuthModalMessage: `You'll now be automatically logged in on your Samsung TV! Wait for a few minutes!` })
                        localStorage.removeItem("_antCQR");
                        setTimeout(() => {
                            setQrAuthResponse(false)
                            // this.setState({ qrAuthResponse: false })
                        }, 3000)
                    } else {
                        // if(message != null && message.includes("Code Doesn't Exist")){
                        //     setQrAuthModalMessage("Code Not found. Please relaunch your Samsung app to get a new login QR!")
                        // }
                        localStorage.removeItem("_antCQR");
                        setQrAuthResponse(true)
                        setQrAuthResponseLoading(false)
                        setQrAuthModalMessage(message)
                        setTimeout(() => {
                            setQrAuthResponse(false)
                        }, 3000)
                    }
                }
            }).catch((err) => {
                if (err) {
                    toast.error('Session Expired! Please Login again.')
                    dispatch(logout())
                    navigate('/signin')
                }
                // console.log(err)
                // console.log(err.message)
            })
    };

    // const handleQRLoginDeny = () => {
    //     setQrAuth(false)
    //     setQrAuthResponseLoading(true);
    //     // this.setState({ qrAuth: false, qrAuthResponseLoading: true })
    //     fetch(
    //         `https://api.antcloud.co/api/tvcodes/decline`,
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `JWT ${userToken}`
    //             },
    //             body: JSON.stringify({
    //                     tvCode: tvCode
    //             }),
    //         }
    //     )
    //         .then((res) => res.json())
    //         .then((response) => {
    //             console.log(response)
    //             let { code, message } = response;
    //             if (code == "success") {
    //                 localStorage.removeItem("_antCQR");
    //                 setQrAuthResponse(true)
    //                 setQrAuthResponseLoading(false)
    //                 setQrAuthModalMessage("You have denied the login request! You'll have to reload your Samsung app to get a new login QR! This pop up will close now!")
    //                 setTimeout(() => {
    //                     setQrAuthResponse(false)
    //                 }, 3000)
    //             } else {
    //                 localStorage.removeItem("_antCQR");
    //                 setQrAuthResponse(true)
    //                 setQrAuthResponseLoading(false)
    //                 setQrAuthModalMessage(message)
    //                 setTimeout(() => {
    //                     setQrAuthResponse(false)
    //                 }, 3000)
    //             }
    //         });
    // }

    const closeSamsungPopUp = () => {
        setQrAuth(false)
        setQrAuthResponse(false);
        setQrAuthResponseLoading(false)
    }

    useEffect(() => {
        const qrAuthId = localStorage.getItem("_antCQR");
        if (qrAuthId) {
            const [tag, code, lastVisit] = qrAuthId.split("^");
            setTvCode(code);
            setQrAuth(true);
        }
    }, [])

    return (
        <>
            {/* //Samsung Modal// */}
            {userToken && loggedIn && (qrAuthResponse || qrAuthResponseLoading) ? (
                <div className="samsung-modal">
                    <div
                        className="samsung-modal-content"
                        style={{ textAlign: "center" }}
                    >
                        <span className="close" onClick={closeSamsungPopUp}>
                            &times;
                        </span>
                        {qrAuthResponseLoading ? <div className="loaderforDatabase" /> : <h3 style={{ color: "#fff", padding: "1rem 0" }}> {qrAuthModalMessage} </h3>}
                    </div>
                </div>
            ) : (
                <></>
            )}

            {userToken && loggedIn && qrAuth ? (
                <div className="samsung-modal">
                    <div
                        className="samsung-modal-content"
                        style={{ textAlign: "center" }}
                    >
                        <span className="close" onClick={closeSamsungPopUp}>
                            &times;
                        </span>
                        <h3 style={{ color: "#fff", marginBottom: "1.5rem" }}> Do you want to Sign In to your Samsung Tv App ?</h3>
                        <h5>
                            <span style={{ color: "#b866cc" }}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{ marginRight: "1rem" }}
                                        onClick={() => handleQRLoginAccept(userToken, userRefreshToken)}
                                    >
                                        <CheckIcon /> Accept
                                    </Button>
                                    {/* <Button variant="contained" color="error" onClick={handleQRLoginDeny}>
                                        <ClearIcon /> Decline
                                    </Button> */}
                                </Box>
                            </span>
                        </h5>
                    </div>
                </div>
            ) : (
                <div />
            )}
            <section className="banner-sec" >
                <div className="container custom-container" >
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="banner-caption" data-aos="fade-up" data-aos-delay="300">
                                <div className="banner-subheading">
                                    <p>
                                        <i className="fas fa-rupee-sign"></i> 599 a month or <i className="fas fa-rupee-sign"></i> 70,000 PC?
                                    </p>
                                    <Link to="/signin" style={{ color: "#ed72ea" }}>Explore <i className="fas fa-chevron-right"></i></Link>
                                </div>
                                <h1 className="main-heading">
                                    A Cloud PC experience like never before
                                </h1>
                                <p className="bannerH-content">
                                    AntCloud provides user a Cloud PC which can be used to play AAA games or to run hardware intensive software, but without emptying your pockets!
                                </p>
                                <Link to="/signin" className="btn-comm">Discover Now</Link>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            {/* Home Video Sec */}

            <section className="videoH-sec">
                <div className="container custom-container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4 videoH-content ">
                            <h2 className="section-heading">Introducing our groundbreaking new platform</h2>
                            <p>AntCloud provides users their own virtual machine to use however they want. such as:</p>
                            <ul className="list-tik-style">
                                <li>Running Graphic Intensive Games</li>
                                <li>Heavy Softwares for Rendering purposes</li>
                                <li>AI/ML Coding </li>
                            </ul>
                            <Link to="/signin" className="btn-comm mt-5">View More <i className="fas fa-chevron-right"></i></Link>
                        </div>
                        <div className="col-lg-6 offset-md-1 videoH-img">
                            <img src={videoImg} style={{ borderRadius: "2rem" }} className="img-fluid" alt="" />
                            <i className="fas fa-play" data-bs-toggle="modal" data-bs-target="#videoModal"></i>
                        </div>
                    </div>
                </div>
            </section>

            {/* Three box  sec*/}

            <section className="points-box-sec">
                <div className="container custom-container">
                    <div className="row">
                        <Parallax>
                            {/* <div className="col-lg-4 points-box image-parallax"> */}
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="300">
                                <div className="points-box-img">
                                    <img src={fourkLg} className="img-fluid animated-icon" alt="" />
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading">Play in 4K</h3>
                                    <p>Enjoy high fidelity gaming by playing in 4K, Full HD or HD in 60 FPS </p>
                                    {/* <Link href="/signin" className="btn-text">View More <i className="fas fa-chevron-right"></i></Link> */}
                                </div>
                            </div>
                            {/* </div> */}
                        </Parallax>
                        <Parallax>
                            {/* <div className="col-lg-4 points-box image-parallax"> */}
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="320">
                                <div className="points-box-img">
                                    <img src={pcImg} className="img-fluid animated-icon" alt="" />
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading">Get your own PC</h3>
                                    <p>Use 'PC mode' to install your own softwares & get access to your PC on whichever device you want!</p>
                                    {/* <Link to="/signin" className="btn-text">View More <i className="fas fa-chevron-right"></i></Link> */}
                                </div>
                            </div>
                            {/* </div> */}
                        </Parallax>
                        <Parallax>
                            {/* <div className="col-lg-4 points-box image-parallax"> */}
                            <div className="points-boxInn" data-aos="fade-up" data-aos-delay="340">
                                <div className="points-box-img">
                                    <img src={galleryLgImg} className="img-fluid animated-icon" alt="" />
                                </div>
                                <div className="points-box-content">
                                    <h3 className="box-heading">Extensive game library</h3>
                                    <p>Gain access to a vast and diverse game library, featuring a wide range of genres and titles.</p>
                                    {/* <Link to="/signin" className="btn-text">View More <i className="fas fa-chevron-right"></i></Link> */}
                                </div>
                            </div>
                            {/* </div> */}
                        </Parallax>
                    </div>
                </div>
            </section>

            {/* Point V sec */}

            <section className="pointsV-home-sec">
                <div className="container-inn custom-container">

                    <div className="gamesH-slider-sec">
                        <div className="container custom-container mb-5">
                            {/* <h2 className="section-heading">Our Partners</h2> */}
                            <h2 className="mb-12 section-heading">Our Partners</h2>
                        </div>
                            <Crousal />
                    </div>
                </div>
            </section>

            {/* Latest News */}
            <LatestNews showNews={true} />

            {/* Modal */}
            <div className="modal fade video-modal" id="videoModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close-modal" data-bs-dismiss="modal">
                            <i className="fas fa-times"></i>
                        </button>
                        <div className="modal-body">
                            <video autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                <source src={joinUsVideo} data-wf-ignore="true" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}