import React, { useEffect, useState } from "react";
import { resetPassword } from "../app/services/auth/forgotPassword";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Components/stream/overlays/spinner";
import { useSelector } from "react-redux";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
    const query = useQuery();
    const token = query.get("token");
    const { loggedIn } = useSelector(state => state.auth);
    
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!token || loggedIn) navigate("/");
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Passwords do not match!");
            return;
        } else {
            setIsLoading(true);
            resetPassword(
                token,
                password,
                () => {
                    setIsLoading(false);
                    toast.success("Password reset successfully");
                    navigate("/signin");
                },
                () => {
                    setIsLoading(false);
                    toast.error("Something went wrong! Try again later.");
                }
            );
        }
    }

    return (
        <section className="user-auth-sec">
            <div
                className="container custom-container"
                data-aos="fade-up"
                data-aos-delay="300"
            >
                <div className="user-auth-box">
                    <div className="user-auth-heading">
                        <h2 className="section-heading">Reset Password!</h2>
                        <p>
                            Please Enter your new password.
                        </p>
                    </div>
                    <div className="user-auth-form-box custom-form-box">
                        <form onSubmit={handleSubmit}>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                name=""
                                className="form-control"
                                placeholder="New password"
                            />
                            <input
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type="password"
                                name=""
                                className="form-control"
                                placeholder="Confirm new password"
                            />
                            <button
                                style={{
                                    ...(isLoading
                                        ? { position: "relative", height: "3rem" }
                                        : {}),
                                }}
                                disabled={isLoading}
                                // onClick={() => {
                                //     if (password !== confirmPassword) {
                                //         toast.error("Passwords do not match");
                                //         return;
                                //     } else {
                                //         setIsLoading(true);
                                //         resetPassword(
                                //             token,
                                //             password,
                                //             () => {
                                //                 setIsLoading(false);
                                //                 toast.success("Password reset successfully");
                                //                 navigate("/signin");
                                //             },
                                //             () => {
                                //                 setIsLoading(false);
                                //                 toast.error("Some error occurred");
                                //             }
                                //         );
                                //     }
                                // }}
                                type="submit"
                                className="btn-comm btn-lg btn-block"
                            >
                                {isLoading ? <Spinner bottomPosition={1} /> : "Reset password"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ResetPassword;