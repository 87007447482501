import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isStreamStarted, setIsStreamStarted] = useState(false);
  const navigate = useNavigate();

  const getCookieValue = (cookieName) => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === cookieName) {
        return value;
      }
    }
    return null;
  }

  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  const logout = () => {
    setIsLoggedIn(false);
    deleteCookie("antplay-tech");
    navigate('/signin', { replace: true });
  }

  const cookieToken = getCookieValue('antplay-tech');

  useEffect(() => {
    if (cookieToken !== null) setIsLoggedIn(true)
    else setIsLoggedIn(false)
  }, []);

  const token = cookieToken;

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, token, logout, isStreamStarted, setIsStreamStarted }}>
      {children}
    </AuthContext.Provider>
  );
};
