import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SamsungLogo from '../images/samsungLogo.webp';
import nvidiaLogo from '../images/nvidiaLogo.webp';
import IntelLogo from '../images/IntelLogo.webp';
import PidLogo from '../images/PidLogo.webp';
import YottaLogo from '../images/YottaLogo.webp';
import AntEsportsLogo from '../images/AntEsports Logo.webp';

const Crousal = () => {
  return (
    <div className="md:px-20 px-2 bg-[#212121] py-10 mt-10">
      {/* <h1 className="md:text-5xl text-2xl mb-12 text-white">Our Parners</h1> */}
      <Carousel
        // additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={1}
        centerMode={false}
        containerClass="container"
        customTransition="all 5s linear"
        draggable
        focusOnSelect={false}
        infinite
        // keyBoardControl
        // minimumTouchDrag={80}
        // pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 2000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        // shouldResetAutoplay
        showDots={false}
        // sliderClass=""
        // slidesToSlide={2}
        // swipeable
        transitionDuration={5000}
      >
       <a target="_blank" rel="noopener noreferrer" href="https://www.samsung.com/in/"> <img style={{ width: 150, height: 150 }} alt="SasmungLogo" src={SamsungLogo} /> </a>
       <a target="_blank" rel="noopener noreferrer" href="https://www.nvidia.com/en-in/"> <img style={{ width: 150, height: 150 }} alt="NvidiaLogo" src={nvidiaLogo} /> </a>
       <a target="_blank" rel="noopener noreferrer" href="https://www.intel.in/content/www/in/en/homepage.html"> <img style={{ width: 150, height: 150 }} alt="IntelLogo" src={IntelLogo} /> </a>
       <a target="_blank" rel="noopener noreferrer" href="https://plugindigital.com/"> <img style={{ width: 150, height: 150 }} alt="PidLogo" src={PidLogo} /> </a>
       <a target="_blank" rel="noopener noreferrer" href="https://yotta.com/"> <img style={{ width: 150, height: 150 }} alt="YottaLogo" src={YottaLogo} /> </a>
       <a target="_blank" rel="noopener noreferrer" href="https://antesports.com/"> <img style={{ width: 150, height: 150 }} alt="AntESportsLogo" src={AntEsportsLogo} /> </a>
      </Carousel>
    </div>
  );
};
export default Crousal;