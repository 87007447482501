import React, { useEffect, useState } from "react";
import LatestNews from "../Components/LatestNews";
import { forgotPassword } from "../app/services/auth/forgotPassword";
import { toast } from "react-toastify";
import Spinner from "../Components/stream/overlays/spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function ForgotPassword() {
    const [page, setPage] = useState(0);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { loggedIn } = useSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if(loggedIn) {
            navigate('/')
        }
    },[])
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        forgotPassword(
            email,
            () => {
                toast.success("Email sent successfully!");
                setIsLoading(false)
                setPage(1);
            },
            () => {
                toast.error("Something went wrong! Try again later.");
                setIsLoading(false)
            }
        );
    }

    return (
        <>
            {/* User Auth Section */}
            <section className="user-auth-sec" style={{ paddingBottom: "12rem" }}>
                <div
                    className="container custom-container"
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    {page === 0 ? (
                        <div className="user-auth-box">
                            <div className="user-auth-heading">
                                <h2 className="section-heading">Forgot Password!</h2>
                                <p>
                                    Please enter your registered email address.
                                </p>
                            </div>
                            <div className="user-auth-form-box custom-form-box">
                                <form onSubmit={handleSubmit}>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        name=""
                                        className="form-control"
                                        placeholder="Email Id"
                                    />
                                    <button
                                        style={{
                                            ...(isLoading
                                                ? { position: "relative", height: "3rem" }
                                                : {}),
                                        }}
                                        disabled={isLoading}
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     setIsLoading(true)
                                        //     forgotPassword(
                                        //         email,
                                        //         () => {
                                        //             toast.success("Email sent");
                                        //             setIsLoading(false)
                                        //             setPage(1);
                                        //         },
                                        //         () => {
                                        //             toast.error("Error while sending mail");
                                        //             setIsLoading(false)
                                        //         }
                                        //     );
                                        // }}
                                        type="submit"
                                        className="btn-comm btn-lg btn-block"
                                    >
                                        {isLoading ? <Spinner bottomPosition={1} /> : 'Send Mail'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    ) : (
                        // <h4 style={{ textAlign: "center", background: "#111", borderRadius: "10px", color: "green", padding: "100px 0px", marginTop: "50px" }}>Email has been sent! Reset your password by clicking on that link</h4>
                        <div style={{ display: "flex", justifyContent: "center", }}>
                            <h4 style={{ textAlign: "center", background: "#171717", maxWidth: "700px", width: "100%", borderRadius: "10px", color: "#22B9A6", padding: "50px 10px", marginTop: "50px" }}>We have sent an email to {email}! If this email is connected to an Antcloud account, you can reset your password.</h4>
                        </div>
                    )}
                </div>
            </section>
            {/* Latest News */}
            {/* <LatestNews /> */}
        </>
    );
}