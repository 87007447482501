import React, { useEffect, useState, useRef } from "react"
// import LatestNews from "../Components/LatestNews"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { registerUser, sendOTP, verifyOTP } from "../features/auth/authActions";
import Spinner from "../Components/stream/overlays/spinner";
import { resetErrorAndMessage } from "../features/auth/authSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getCities, statesOfIndia } from "../assets/stateCity";
import axios from "axios";

export default function SignUp() {
    const [formData, setFormData] = useState({});
    const [phone, setPhone] = useState("");
    const [tvCode, setTvCode] = useState(false);
    const [pincode, setPincode] = useState("");

    const length = 6;
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const [otpInput, setOtpInput] = useState(false);
    const inputRefs = useRef([]);
    const [isOtpSent, setIsOtpSent] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, userInfo, message, error, success, otpVerifiedMessage, loggedIn, otpVerifiedError } = useSelector(state => state.auth);
    const { register, handleSubmit, setValue } = useForm();

    const location = useLocation();
    const query = location.search;

    // const [cityList, setCityList] = useState([]);
    // const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const handleOTPChange = (index, value) => {
        if (!isNaN(value)) {
            const updatedOTP = [...otp];
            updatedOTP[index] = value;
            setOtp(updatedOTP);

            // Move to the next input field
            if (value && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleOTPKeyDown = (index, event) => {
        // Move to the previous input field on pressing backspace
        if (event.key === 'Backspace' && index > 0 && !otp[index]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleGetOtp = async () => {
        setOtp(new Array(length).fill(''))
        if (validator.isMobilePhone(phone, 'en-IN')) {
            // setOtpInput(true)
            dispatch(sendOTP({ phone: `+91${phone}`, signup: true }))
        } else {
            toast.error('Please Enter A Valid 10 Digit Indian Phone Number')
        }
    }

    const submitForm = (formData) => {
        if (formData.fullName === "") {
            toast.error("Full Name field can't be blank.")
        } else if (formData.email === "" || !validator.isEmail(formData.email)) {
            toast.error("Invalid Email Address.");
        } else if (formData.password === "" || formData.password.length < 6) {
            toast.error("Password should be of atleast 6 characters.")
        } else if (formData.confirmPassword === "" || (formData.password !== formData.confirmPassword)) {
            toast.error("Passwords do not match.")
        } else if (otp.join('').length < 6) {
            toast.error('OTP should be a 6 digit number.')
        } else if (state === "") {
            toast.error("State field can not be empty.")
        // } else if (city === "") {
        //     toast.error("City field can not be empty")
            //} else if (formData.city === "") {
            //     toast.error("City field can not be empty")
        } else if (formData.Pincode === "") {
            toast.error("Pincode field can not be empty.")
        } else if (formData.Pincode.length < 6) {
            toast.error('Please enter a valid 6 digit pincode.')
        }

        else {
            const fullNameSplit = formData.fullName.split(" ");
            formData.email = formData.email.toLowerCase();
            formData.fName = fullNameSplit.shift();
            formData.lastName = fullNameSplit.join(' ');
            formData.phone = `+91${phone}`;
            const { fullName, confirmPassword, ...newFormData } = formData;
            if (location.pathname === "/signup" && query && query.includes("source=SAM") || tvCode) newFormData.source = "Samsung"
            else newFormData.source = "Browser"
            newFormData.loc = {};
            // newFormData.loc.City = formData.city;
            // newFormData.loc.City = city;
            newFormData.loc.State = state;
            newFormData.loc.Pincode = pincode;
            setFormData(newFormData);
            dispatch(verifyOTP({ phone: `+91${phone}`, _verificationToken: otp.join('') }));
        }
    }

    const handleStateChange = (e) => {
        console.log(e.target.value)
        setState(e.target.value)
        // const list = getCities(e.target.value)
        // setCityList([])
        // setCity("")
        setPincode("")
        // setCityList(list)
    }

    useEffect(() => {
        if (message && success) {
            setIsOtpSent(true);
            setOtpInput(true)
            toast.success(message)
            dispatch(resetErrorAndMessage())
        }
        if (error) {
            dispatch(resetErrorAndMessage())
            toast.error(error)
        }
        if (userInfo && loggedIn && success) {
            if (location.pathname === "/signup" && query && query.includes("source=SAM") || tvCode) navigate('/')
            else if (window.matchMedia("only screen and (max-width: 992px)").matches) navigate('/pricing')
            else if (userInfo.currentPlan === "Basic") navigate('/pricing')
            // else if (window.matchMedia("only screen and (max-width: 992px)").matches) navigate('/pricing?signup=true')
            // else if (userInfo.currentPlan === "Basic") navigate('/pricing?signup=true')
            else navigate('/stream')
            dispatch(resetErrorAndMessage())
        }
    }, [message, userInfo, success, error])

    useEffect(() => {
        if (otpVerifiedMessage) {
            dispatch(registerUser(formData));
        } else if (otpVerifiedError) {
            toast.error(otpVerifiedError)
        }
    }, [otpVerifiedError, otpVerifiedMessage])

    useEffect(() => {
        const qrAuthId = localStorage.getItem("_antCQR");
        if (qrAuthId) {
            const [tag, code, lastVisit] = qrAuthId.split("^");
            setTvCode(code);
        }

        if (loggedIn && window.matchMedia("only screen and (max-width: 992px)").matches) {
            navigate('/')
        } else if (loggedIn && qrAuthId) {
            navigate('/')
        } else if (loggedIn) {
            navigate('/stream')
        }
    }, [])

    return (
        <>
            {/* User Auth Section */}
            <section className="user-auth-sec">
                <div className="container custom-container" data-aos="fade-up" data-aos-delay="300">
                    <div className="user-auth-box">
                        <div className="user-auth-heading">
                            <h2 className="section-heading">Get Started!</h2>
                            <p>Welcome to Ant Cloud! Sign up by providing the details listed below.</p>
                        </div>
                        <div className="user-auth-form-box custom-form-box">
                            <form onSubmit={handleSubmit(submitForm)}>
                                <input
                                    type="text"
                                    {...register('fullName')}
                                    className="form-control"
                                    placeholder="Full name"
                                    required
                                    onChange={(e) => setValue("fullName", e.target.value.replace(/[^a-zA-Z\s]/g, ""))}
                                />

                                <input
                                    type="email"
                                    {...register('email')}
                                    className="form-control"
                                    placeholder="Email address"
                                    required
                                />

                                <input
                                    type="password"
                                    {...register('password')}
                                    className="form-control"
                                    placeholder="Password"
                                    required
                                />

                                <input
                                    type="password"
                                    {...register('confirmPassword')}
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    required
                                />

                                {/* <input
                                    type="text"
                                    {...register('city')}
                                    className="form-control"
                                    placeholder="City"
                                    required
                                /> */}

                                <FormControl
                                    className="mb-4" style={{ borderRadius: "20px !important" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">{`${state ? "" : "Select state"}`}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state}
                                        onChange={handleStateChange}
                                    >
                                        {statesOfIndia.map((item) => (
                                            <MenuItem value={item.value}>{item.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <FormControl
                                    className="mb-4" style={{ borderRadius: "20px !important" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">{`${city ? "" : "Select city"}`}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={city}
                                        // label="Age"
                                        onChange={(e) => setCity(e.target.value)}
                                    >
                                        {cityList.map((item) => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}

                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="6"
                                    {...register('Pincode')}
                                    className="form-control"
                                    placeholder="Pincode"
                                    required
                                    value={pincode}
                                    onChange={(e) => {
                                        setPincode(() => {
                                            const a = e.target.value.replace(/\D/g, "");
                                            // if (a.length === 6) {
                                            //     getState(a)
                                            // }
                                            return a;
                                        })
                                    }}
                                // onChange={(e) => {
                                //     setPincode(e.target.value.replace(/\D/g, ""))
                                // }}
                                />

                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    {...register('phone')}
                                    className="form-control"
                                    placeholder="Phone number"
                                    required
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value.replace(/\D/g, ""))
                                        setOtpInput(false)
                                    }}
                                />
                                <button
                                    disabled={!phone || (phone && phone.length !== 10)}
                                    type="button"
                                    style={{
                                        ...(otpInput ? { display: "none" } : { display: "block" }),
                                        ...(loading ? { position: "relative", height: "3rem" } : {})
                                    }}
                                    onClick={handleGetOtp}
                                    className="btn-comm btn-lg btn-block getOtp"
                                >
                                    {loading ? <Spinner bottomPosition={1} /> : 'Get OTP'}
                                </button>

                                <div className="otp-number" style={otpInput ? { display: "block" } : { display: "none" }}>
                                    <div className="otp-container">
                                        {otp.map((value, index) => (
                                            <input
                                                key={index}
                                                className="otp-field"
                                                type="text"
                                                maxLength="1"
                                                value={value}
                                                // style={errField === "otp" ? { backgroundColor: "#f08080" } : { backgroundColor: "#fff" }}
                                                onChange={(e) => handleOTPChange(index, e.target.value)}
                                                onKeyDown={(e) => handleOTPKeyDown(index, e)}
                                                ref={(ref) => inputRefs.current[index] = ref}
                                            />
                                        ))}
                                    </div>
                                    <button
                                        type="button"
                                        className="resendOTPButton"
                                        onClick={handleGetOtp}>
                                        Resend OTP <i className="fas fa-redo otpBackBtn"></i>
                                    </button>
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className="btn-comm btn-lg btn-block"
                                        style={loading ? { position: "relative", height: "3rem" } : {}}
                                    >
                                        {loading ? <Spinner bottomPosition={1} /> : 'Create Account'}
                                    </button>
                                </div>

                            </form>
                            <p className="p-btns mt-1" style={{ paddingTop: "1rem" }}>
                                Already have an account?
                                <Link to="/signin" className="btn-text"> Log In</Link>
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            {/* Latest News */}
            {/* <LatestNews /> */}
        </>
    )
}