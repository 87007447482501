import React, { useEffect, useState } from "react"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ThankYou() {

    return (
        <>
            {/* <div style={{ background: "#171717" }}> */}
            <div className="container" style={{ display: "flex", justifyContent: "center", width: "100%", minHeight: 'calc(100vh - 380px)', maxHeight: "768px", alignItems: "center" }}>
                <Card className="thankyou-box">
                    <CardContent>
                        <span style={{ fontSize: "70px", color: "#22B9A6" }}>
                            <i class="fas fa-check-circle"></i>
                        </span>
                        <Typography gutterBottom variant="h3" sx={{ color: "#22B9A6" }} component="div">
                            Payment Success
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#fff" }}>
                            Your payment has been succesfully captured! Your additional time will be allocated shortly.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            {/* </div> */}
        </>
    )
}