import React, { useRef } from "react"

export default function ShippingPolicy() {
    return (
        <>
            <section style={{ padding: "3rem 0" }} className="help-section writter-artical-details help-section-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading-innerBanner">
                                <h2 className="section-heading text-center">
                                    <span> Shipping Policy</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div style={{ border: "1px solid #ddd", padding: "4rem 60px 12px", marginTop: "3rem" }} className="help-box">
                                <div className="help-box-inner2 mt-0">
                                    <div className="single-artical">
                                        <div className="content">
                                            <div className="inner-content">
                                                <h4 className="title">
                                                    1. Service Activation
                                                </h4>
                                                <p className="text">
                                                    Ant Cloud operates entirely online, providing instant access to our gaming platform upon subscription or purchase. No physical shipping of products is involved.                                                </p>
                                                <h4 className="title">
                                                    2. Subscription Activation
                                                </h4>
                                                <p className="text">
                                                    Upon successful payment processing, subscriptions to Ant Cloud are activated immediately. Users will receive confirmation emails containing login credentials and detailed instructions on accessing the gaming platform.                                                </p>
                                                <h4 className="title">
                                                    3. Access
                                                </h4>
                                                <p className="text">
                                                    Our gaming platform is accessible from any location with an internet connection. Users can enjoy seamless gaming experiences on various devices including PCs, laptops, smartphones, tablets, and gaming consoles.                                                </p>
                                                <h4 className="title">
                                                    4. Account Setup
                                                </h4>
                                                <p className="text">
                                                    Users are responsible for setting up their accounts post-subscription activation. This entails creating a unique username, password, and any additional security measures deemed necessary. We strongly advise users to opt for strong passwords and to safeguard their account information.                                                </p>
                                                <h4 className="title">
                                                    5. Billing
                                                </h4>
                                                <p className="text">
                                                    Billing for Ant Cloud services occurs upon subscription activation. Users can choose from a variety of payment methods to complete transactions securely.                                                </p>
                                                <h4 className="title">
                                                    6. Customer Support
                                                </h4>
                                                <p className="text">
                                                    Should you encounter any difficulties with account setup, subscription activation, or accessing our gaming platform, our dedicated customer support team is available to assist you. Reach out to us via email for prompt resolution of your queries.                                                </p>
                                                <h4 className="title">
                                                    7. Service Area
                                                </h4>
                                                <p className="text">
                                                    Ant Cloud's services are available throughout India. Users can enjoy uninterrupted gaming experiences regardless of their location within the country.                                                </p>
                                                <h4 className="title">
                                                    8. Updates and Notifications
                                                </h4>
                                                <p className="text">
                                                    Users will receive timely updates and notifications regarding service maintenance, new features, and any other pertinent information via email or within the Ant Cloud platform.                                                </p>
                                                <p className="text">
                                                    By subscribing to Ant Cloud, you agree to abide by the terms outlined in this Shipping Policy. If you have any further inquiries or require assistance, please don't hesitate to contact our support team.
                                                    Thank you for choosing Ant Cloud for your Cloud Gaming needs!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}