import React, { useState, useEffect, useRef } from "react"
import LatestNews from "../Components/LatestNews"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { sendOTP, userEmailLogin, userPhoneLogin } from "../features/auth/authActions";
import { resetErrorAndMessage } from "../features/auth/authSlice";
import validator from "validator";
import Spinner from "../Components/stream/overlays/spinner";

export default function SignIn() {
    const [phone, setPhone] = useState(null);
    const length = 6;
    const [otpInput, setOtpInput] = useState(false);
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const [phoneErr, setPhoneErr] = useState(false);
    const [otpErr, setOTPErr] = useState(false);
    const [tvCode, setTvCode] = useState(false);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, userInfo, message, error, success, loggedIn, otpVerifiedMessage, otpVerifiedError } = useSelector(state => state.auth);
    const { register, handleSubmit, setValue, getValues } = useForm();


    const handleOTPChange = (index, value) => {
        if (!isNaN(value)) {
            const updatedOTP = [...otp];
            updatedOTP[index] = value;
            setOtp(updatedOTP);

            // Move to the next input field
            if (value && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleOTPKeyDown = (index, event) => {
        // Move to the previous input field on pressing backspace
        if (event.key === 'Backspace' && index > 0 && !otp[index]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleGetOtp = async () => {
        setOtp(new Array(length).fill(''))
        if (validator.isMobilePhone(phone, 'en-IN')) {
            // setOtpInput(true)
            dispatch(sendOTP({ phone: `+91${phone}`, signup: false }))
        } else {
            toast.error('Please Enter A Valid 10 Digit Indian Phone Number')
        }
    }

    const submitEmailForm = (formData) => {
        if (formData.email === "" || !validator.isEmail(formData.email)) {
            toast.error("Invalid Email Address.");
        } else if (formData.password === "") {
            toast.error("Password Field can not be empty.")
        } else {
            formData.email = formData.email.toLowerCase();
            const { phone, ...newFormData } = formData;
            dispatch(userEmailLogin(newFormData));
        }
    }

    const submitPhoneForm = (formData) => {
        if (phone === "") toast.error("Phone Field Can't be empty")
        else if (otp.join('').length < 6) toast.error("Enter a valid 6 digit OTP");
        else dispatch(userPhoneLogin({ phone: `+91${phone}`, _verificationToken: otp.join('') }))
    }

    useEffect(() => {
        if (success) {
            setOtpInput(true)
            toast.success(message)
            dispatch(resetErrorAndMessage())
        }
        if (error) {
            toast.error(error)
            dispatch(resetErrorAndMessage())
        }
        if (userInfo && loggedIn && success) {
            toast.success(`Welcome Back ${userInfo.firstName}!`);
            dispatch(resetErrorAndMessage())
            if (tvCode) navigate('/')
            else if (window.matchMedia("only screen and (max-width: 992px)").matches) navigate('/download')
            else if (userInfo.currentPlan === "Basic") navigate('/pricing')
            else navigate('/stream')
        }
    }, [message, loggedIn, success, error])

    useEffect(() => {
        const qrAuthId = localStorage.getItem("_antCQR");
        if (qrAuthId) {
            const [tag, code, lastVisit] = qrAuthId.split("^");
            setTvCode(code);
        }

        if (loggedIn && window.matchMedia("only screen and (max-width: 992px)").matches) {
            navigate('/')
        } else if (loggedIn && qrAuthId) {
            navigate('/')
        } else if (loggedIn) {
            navigate('/stream')
        }
    }, [])

    return (
        <>
            {/* User Auth Section */}
            <section className="user-auth-sec">
                <div className="container custom-container" data-aos="fade-up" data-aos-delay="300">
                    <div className="user-auth-box">
                        <div className="user-auth-heading">
                            <h2 className="section-heading">Hello Again!</h2>
                            <p>Welcome back to Ant Cloud. Access your account by providing your unique credentials.</p>
                        </div>
                        <div className="user-auth-form-box custom-form-box">
                            {/* <form> */}
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#home">Sign in via Phone</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#menu1">Sign in via Email/Password</a>
                                </li>

                            </ul>
                            <div className="tab-content">
                                <div id="home" className="container tab-pane active"><br />
                                    <form onSubmit={handleSubmit(submitPhoneForm)}>
                                        <h3 className="box-heading">Sign in via Phone</h3>
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            maxLength="10"
                                            className="form-control"
                                            placeholder="Phone number"
                                            required
                                            value={phone}
                                            onChange={(e) => {
                                                setPhone(e.target.value.replace(/\D/g, ""))
                                                setOtpInput(false)
                                            }}
                                        />
                                        <button
                                            disabled={!phone || (phone && phone.length !== 10)}
                                            type="button"
                                            style={{
                                                ...(otpInput ? { display: "none" } : { display: "block" }),
                                                ...(loading ? { position: "relative", height: "3rem" } : {})
                                            }}
                                            onClick={handleGetOtp}
                                            className="btn-comm btn-lg btn-block getOtp"

                                        >
                                            {loading ? <Spinner bottomPosition={1} /> : 'Get OTP'}
                                        </button>
                                        <div className="otp-number" style={otpInput ? { display: "block" } : { display: "none" }}>
                                            <div className="otp-container">
                                                {otp.map((value, index) => (
                                                    <input
                                                        key={index}
                                                        className="otp-field"
                                                        type="text"
                                                        maxLength="1"
                                                        value={value}
                                                        onChange={(e) => handleOTPChange(index, e.target.value)}
                                                        onKeyDown={(e) => handleOTPKeyDown(index, e)}
                                                        ref={(ref) => inputRefs.current[index] = ref}
                                                    />
                                                ))}
                                            </div>

                                            <button
                                                type="button"
                                                className="resendOTPButton"
                                                onClick={handleGetOtp}>
                                                Resend OTP <i className="fas fa-redo otpBackBtn"></i>
                                            </button>
                                            <button
                                                disabled={loading || otp.join('').length < 6}
                                                type="submit"
                                                className="btn-comm btn-lg btn-block"
                                                style={loading ? { position: "relative", height: "3rem" } : {}}
                                            >
                                                {loading ? <Spinner bottomPosition={1} /> : 'Login'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div id="menu1" className="container tab-pane fade"><br />
                                    <h3 className="box-heading">Sign in via Email/Password</h3>
                                    <form onSubmit={handleSubmit(submitEmailForm)}>
                                        <input
                                            type="email"
                                            {...register('email')}
                                            className="form-control email"
                                            placeholder="Email"
                                            required
                                        />
                                        <input
                                            type="password"
                                            {...register('password')}
                                            className="form-control password"
                                            placeholder="Password"
                                            required
                                        />
                                        <Link to='/forgot-password' hr style={{ textAlign: "left", color: "#90DFA5", textDecoration: "underline" }}>Forgot Password?</Link>
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="btn-comm btn-lg btn-block emailPassword"
                                            style={loading ? { position: "relative", height: "3rem" } : {}}
                                        >
                                            {loading ? <Spinner bottomPosition={1} /> : 'Sign In'}
                                        </button>

                                    </form>

                                </div>
                            </div>
                            <p className="p-btns mt-2">
                                Not registered yet?
                                <Link to="/signup" className="btn-text"> Create an Account</Link>
                            </p>
                            {/* <p className="p-btns mt-2">
                                Forgot password?
                                <Link to="/forgot-password" className="btn-text"> Click here!</Link>
                            </p> */}
                            {/* </form> */}
                        </div>
                        {/* <p className="p-btns mt-1">
                            Not registered yet?
                            <Link to="/signup" className="btn-text"> Create an Account</Link>
                        </p> */}
                    </div>
                </div>
            </section>

            {/* Latest News */}
            {/* <LatestNews /> */}
        </>
    )
}