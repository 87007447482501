import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import logo from '../images/Ant-Logo.webp';
import { useAuth } from "../AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useLogoutQuery } from "../app/services/auth/logoutService";
import { removeCredentials } from "../features/auth/userSlice";
import { toast } from 'react-toastify';
import EmailVerification from "./EmailVerification";
import Maintenance from "./Maintenance";

export default function Header() {
    const [mobileNav, setMobileNav] = useState(false);
    // const { isLoggedIn, logout } = useAuth();
    const [showMobileDropDown, setShowMobileDropDown] = useState(false)
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 992);
    const [isLogoutRequest, setIsLogoutRequest] = useState(true);
    const { loggedIn, userInfo } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMobileDevice = () => {
        return window
            .matchMedia("only screen and (max-width: 992px)").matches;
    }

    const { data, error } = useLogoutQuery('logout', { skip: isLogoutRequest });

    const handleLogout = () => {
        setIsLogoutRequest(false);
    }

    useEffect(() => {
        if (data && data.message === "You have been logged out succesfully!") {
            dispatch(logout());
            dispatch(removeCredentials());
            navigate('/signin');
            toast.success("Come Back Soon!")
        } else if (error) {
            toast.error('Error Logging Out!')
        }
    }, [data, error])

    return (
        <div>
            <EmailVerification />
            <header style={loggedIn && userInfo.emailVerified === false
                ? { marginTop: "40px" }
                : {}} className="dark-bg">
                <div className="container custom-container">
                    <Link to="/" className="header-logo">
                        <img src={logo} alt="Ant-Logo" />
                    </Link>
                    <nav className="navbar-cust">
                        <span className="menu-icon-mobile" onClick={() => setMobileNav(!mobileNav)}><i className="fas fa-lg fa-bars"></i></span>
                        <ul className={mobileNav ? 'navbarInn' : ''}>
                            <span className="nav-cust-close" onClick={() => setMobileNav(false)}><i className="fas fa-lg fa-times"></i></span>
                            <li onClick={() => setMobileNav(false)}>
                                <Link to="/download">Download</Link>
                            </li>
                            {/* {loggedIn
                            ? <li>
                                <Link to="/download">Download</Link>
                            </li>
                            : <li onClick={() => setShowMobileDropDown(!showMobileDropDown)} className="drp">
                                <a href="#">Who We Are <i className="fas fa-chevron-down"></i></a>
                                <ul style={window.innerWidth <= 992 ? showMobileDropDown ? { display: "block" } : { display: "none" } : { display: "block" }} className="drp-menu">
                                    <li>
                                        <Link to="/about">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/testimonials">Testimonials</Link>
                                    </li>
                                    <li>
                                        <Link to="/download">Download</Link>
                                    </li>
                                </ul>
                            </li>
                        } */}

                            <li onClick={() => setMobileNav(false)}>
                                <Link to="/pricing">Pricing</Link>
                            </li>
                            {loggedIn
                                ?
                                <li onClick={() => setMobileNav(false)}>
                                    <Link to="/support"> Support</Link>
                                </li>
                                :
                                <li onClick={() => setMobileNav(false)}>
                                    <Link to="/faq"> FAQ</Link>
                                </li>
                            }

                            {
                                loggedIn && isMobileDevice()
                                    ?
                                    <li onClick={() => setMobileNav(false)}>
                                        <a style={{ color: "#fff" }} onClick={handleLogout}> Logout </a>
                                    </li>
                                    :
                                    <Link
                                        onClick={() => setMobileNav(false)}
                                        to="/signup"
                                        className="btn-comm btn-cta-mob"
                                    >
                                        Start Now
                                    </Link>
                            }
                        </ul>
                    </nav>
                    {
                        loggedIn && !isMobileDevice()
                            ? <>
                                <Link className="header-btn" to="/stream"> Dashboard </Link>
                                <h4 style={{ margin: "0", padding: "0 1rem" }}>|</h4>
                                <button className="header-btn" onClick={handleLogout}> Logout </button>
                            </>
                            : <Link to="/signup" className="btn-comm"> Start Now </Link>
                    }
                </div>
            </header>
        </div>
    )
}