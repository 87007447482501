import React, { useEffect, useState } from "react"
import styles from './streampage.module.css';
import Stream from '../Components/stream/page';
import Spinner from '../Components/stream/overlays/spinner';
import { useDispatch, useSelector } from "react-redux";

export default function AdminStream() {
  const [startStream, setStartStream] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [serverId, setServerId] = useState('');
  const { userToken } = useSelector(state => state.auth);

  // const token = getCookieValue("antplay-tech");


  useEffect(() => {
    fetch('https://api.antcloud.co/api/users/me', {
      headers: {
        "Authorization": `JWT ${userToken}`
      }
    }).then(async (res) => {
      const result = await res.json();
      if (res.status === 200) {
        setUser(result.user);
      } else {
        console.log(result)
      }
      setLoaded(true);
    }).catch(err => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    if (!startStream) document.body.style.overflowY = 'scroll';
  }, [startStream])

  const stream = () => {
    setStartStream(true);
  }

  const stopStream = () => {
    setStartStream(false);
  }

  const setValue = (event) => {
    setServerId(event.target.value);
  }
  
  return (
    <div className={styles.Global}>
      {
        loaded ?
          startStream ?
            <Stream stopStream={stopStream} streamStatus={''} admin={true} serverId={serverId}/>
            :<div>
              <input value={serverId} onChange={setValue}></input>
              <button onClick={stream}>Start</button>
            </div>
          : <Spinner />
      }
    </div>
  );
}