import React, { useRef, useEffect, useState } from "react"
import styles from './streampage.module.css';
import Stream from '../Components/stream/page';
import Spinner from '../Components/stream/overlays/spinner';
import { Dashboard } from '../Components/Dashboard/Dashboard';
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailsQuery } from "../app/services/auth/authService";
import { setCredentials } from "../features/auth/userSlice";
import { logout, refreshTokens, setUserPlan } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import { useRefreshUserTokenQuery } from "../app/services/auth/refreshService";
import EmailVerification from "../Components/EmailVerification";
import { zoom } from "../Components/zoom";

export default function StreamPage() {
  const [startStream, setStartStream] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [games, setGames] = useState([]);
  const gamesRef = useRef(null);
  gamesRef.current = gamesRef;
  const type = useRef(null);
  const [streamStatus, setStreamStatus] = useState('');
  const [user, setUser] = useState(null);
  const { isLoggedIn, token, setIsStreamStarted } = useAuth();
  const { loggedIn, userToken, userRefreshToken, userInfo } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshDataRef = useRef();
  const refreshErrorRef = useRef();

  refreshDataRef.current = refreshDataRef;
  refreshErrorRef.current = refreshErrorRef;

  const [skipRefresh, setSkipRefresh] = useState(true)
  const [skipMe, setSkipMe] = useState(true)

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paramIdToken = params.get('idToken');
    type.current = params.get('type');

    if (type.current === 'mobile') {
      setLoaded(true);
      setStartStream(true);
      if (window.Android) window.Android.showMessageInNative('loaded');
      window.addEventListener('webview', event => {
        if (event.detail.start) {
          if (window.Android) window.Android.showMessageInNative('started');
          setLoaded(true);
          setStartStream(true);
        }
        if (event.detail.paused) {
          setStreamStatus('paused')
        }
        if (event.detail.resumed) {
          setStreamStatus('resumed')
        }
      })
    } else if (userToken) {
      setSkipMe(false);
      // fetch('https://api.antcloud.co/api/users/me', {
      //   headers: {
      //     "Authorization": `JWT ${userToken}`
      //   }
      // }).then(async (res) => {
      //   const result = await res.json();
      //   if (res.status === 200) {
      //     result.user.token = result.token;
      //     setUser(result.user);
      //   } else {
      //     setUser(result)
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
      // fetch('https://api.antcloud.co/api/globals/games')
      //   .then((res) => res.json())
      //   .then((res) => {
      //     setGames(res.mobileGames[0].games);
      //   })
    }

    if (!paramIdToken && !loggedIn) {
      navigate('/signin')
    } else if (!paramIdToken && window.matchMedia("only screen and (max-width: 760px)").matches) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (games.length) {
      setLoaded(true);
    }
  }, [games]);

  useEffect(() => {
    if (!startStream) document.body.style.overflowY = 'scroll';
    if (startStream) zoom.out();
  }, [startStream])

  const stream = (game) => {
    localStorage.setItem('game', game);
    setStartStream(true);
    setIsStreamStarted(true);
  }

  const stopStream = () => {
    setStartStream(false);
    setIsStreamStarted(false);
    if (type.current === 'mobile') if (window.Android) window.Android.showMessageInNative('ended');
  }

  // const { data: userData, error: userError } = useGetUserDetailsQuery('userDetails');
  // const { data: userData, error: userError, refetch: getUserDetails } = useGetUserDetailsQuery('userDetails', { skip: skipMe });
  // const { data: refreshData, error: refreshError } = useRefreshUserTokenQuery('refreshTokens', { skip: skipRefresh });

  const fetchMe = async (meToken) => {
    try {
      const userData = await fetch('https://api.antcloud.co/api/users/me',
        {
          method: 'GET',
          headers: {
            'Authorization': `JWT ${meToken}`
          }
        }
      )
      const user = await userData.json();
      return user
    } catch (err) {
      console.log(err)
      toast.error(err)
    }
  }

  const fetchRefresh = () => {
    return fetch('https://api.antcloud.co/api/users/refresh',
      {
        method: 'GET',
        headers: {
          'refresh': `JWT ${userRefreshToken}`
        }
      }
    ).then((res) => res.json())
      .then(res => {
        return res
      })
  }

  useEffect(() => {
    if (loggedIn) {
      const tryMe = (meToken, refreshToken) => {
        fetchMe(meToken, refreshToken)
          .then(userData => {
            if (userData.message) {
              if (userData.message === 'Token Expired') {
                fetchRefresh().then(refreshData => {
                  if (refreshData.message === 'Re-authentication needed!' || refreshData.message === 'Forbidden Request!' || refreshData.message === 'Refresh Token Expired') {
                    toast.error('Session Expired! Please Sign in again.')
                    navigate('/signin');
                    dispatch(logout())
                  } else if (refreshData.message === "New Access Pairs Generated!") {
                    dispatch(refreshTokens(refreshData));
                    tryMe(refreshData.accessToken, refreshData.refreshToken);
                  }
                })
              } else if (userData.message === 'Invalid Token') {
                toast.error('Session Expired! Please Sign in again.')
                navigate('/signin');
                dispatch(logout())
              }
            } else {
              dispatch(setCredentials(userData.user))
              const userPayload = {
                id: userData.user.id,
                firstName: userData.user.firstName,
                currentPlan: userData.user.currentPlan,
                emailVerified: userData.user.emailVerified,
                email: userData.user.email
              }
              dispatch(setUserPlan(userPayload));
            }
          }).catch(err => {
            console.log(err)
          })
      }
      tryMe(userToken, userRefreshToken);

      fetch('https://api.antcloud.co/api/globals/games')
        .then((res) => res.json())
        .then((res) => {
          setGames(res.mobileGames[0].games);
        })
    }
  }, []);

  // useEffect(() => {
  //   if (refreshData) {
  //     dispatch(refreshTokens(refreshData))
  //     getUserDetails()
  //   }
  //   else if (refreshError) {
  //     console.log(refreshError)
  //     toast.error('Session Expired! Please Sign in again.')
  //     navigate('/signin');
  //     dispatch(logout())
  //   }
  // }, [refreshData, refreshError])

  // if (!userData && !userError) {
  //   return <Spinner topPosition={40} />;
  // }

  return (
    <div style={user == null ? { paddingBottom: "2%" } : { paddingBottom: "0" }} className={styles.Global}>
      {/* <Dashboard games={games} stream={stream} /> */}
      <EmailVerification />
      {
        loaded
          ? startStream
            ? <Stream stopStream={stopStream} streamStatus={streamStatus} />
            : <Dashboard games={games} user={user} stream={stream} />
          : <Spinner topPosition={40} />
      }
    </div>
  );
}