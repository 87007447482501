import styles from "./formInput.module.scss";
import { useState, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';

function FormInput({
  name,
  value,
  label,
  valueChange,
  children,
  otpModal,
  verify = false,
  style,
  ...otherProps
}) {
  const [input, setInput] = useState(value);
  const handleChange = ({ target: { name, value } }) => {
    setInput(value);
    valueChange({ name, value });
  };
  useEffect(() => {
    setInput(value);
  }, [value]);
  return (
    <div className={styles.input_box} style={style}>
      {/* <Tooltip title={name==="phone" ? "Please Enter A 10 Digit Number" : "" }> */}
        <input
          name={name}
          value={input}
          // pattern={name === "phone" ? "[789][0-9]{9}" : false}
          {...otherProps}
          onChange={handleChange}
        />
      {/* </Tooltip> */}

      {/* {name === "phone" && verify && (
        <span className={styles.get_otp} onClick={otpModal}>
          Verify
        </span>
      )} */}
      <span className={styles.input_border}>
        <i></i>
      </span>

      <label htmlFor={otherProps.id}>{label}</label>
    </div>
  );
}

export default FormInput;