import React, { useEffect, useState } from "react"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import Spinner from "../Components/stream/overlays/spinner";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function EmailVerify() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();
    const query = location.search;

    useEffect(() => {
        if (query) {
            let verificationToken = query.split("code=")[1];
            setLoading(true)
            fetch(`https://api.antcloud.co/api/users/email/verify?token=${verificationToken}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.message) {
                        if (data.message === "Email Verified Successfully!") {
                            setSuccess('Email Verified Successfully!')
                            toast('Email Verified Successfully!')
                            navigate('/stream');
                            setLoading(false)
                        } else {
                            setLoading(false)
                            toast.error(data.message)
                            setError(data.message)
                        }
                    }
                }).catch(err => {
                    setLoading(false)
                    console.log(err);
                    toast.error(err.message)
                })
        } else setError('No Authorization Token Found')
    }, [])
    return (
        <>
            <div className="container" style={{ display: "flex", justifyContent: "center", width: "100%", minHeight: 'calc(100vh - 380px)', maxHeight: "768px", alignItems: "center" }}>
                <Card className="thankyou-box" style={{ position: "relative" }}>
                    { 
                        loading 
                        ? <Spinner /> 
                        : <CardContent>
                            <span style={{ fontSize: "70px", color: "#22B9A6" }}>
                                {error ? <CancelIcon sx={{ fontSize: "70px" }} /> : success ? <CheckCircleIcon sx={{ fontSize: "70px" }} /> : null}
                            </span>
                            <Typography gutterBottom variant="h3" sx={{ color: "#22B9A6" }} component="div">
                                {loading ? null : error ? error : success}
                            </Typography>
                        </CardContent>
                    }
                </Card>
            </div>
        </>
    )
}