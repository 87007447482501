import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
let userId;

export const updateUserApi = createApi({
    reducerPath: 'updateUserApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.antcloud.co/api/',
        // baseUrl: 'http://localhost:8000/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userToken;
            userId = getState().user.userDetails.id;
            if(token) {
                headers.set('Authorization', `JWT ${token}`)
                return headers
            }
        },
    }),
    endpoints: (builder) => ({
        updateUserProfile: builder.mutation({
            query: (userData) => ({
                url: `users/${userId}`,
                method: 'PATCH',
                body: userData
            })
        })
    })
})

export const { useUpdateUserProfileMutation } = updateUserApi