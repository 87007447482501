import style from "./billingDetails.module.scss";
import FormInput from "../form-input/FormInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../stream/overlays/spinner";

function BillingDetails() {
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.user.userDetails);

  useEffect(() => {
    if (!user) setLoading(true)
    else setLoading(false)
  }, [user])

  const getDateString = (date) => {
    date = new Date(date);
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
    date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = monthNames[date.getMonth()];
    return date.getDate() + " " + month + " " + date.getFullYear();
  };

  let renewDate = null,
    upgrade = true;

  const timeLeft = () => {
    if (user.totalTimeMonth !== 0) {
      const minutes = user.totalTimeMonth - user.timeUsedMonth;
      const hours = Math.floor(minutes / 60); // Get the whole number of hours
      const remainingMinutes = minutes % 60; // Get the remaining minutes after converting to hours
      return hours + " hours " + remainingMinutes + " minutes";
    } else return 'N/A'
  }

  const daysLeft = (dateString) => {
    if (dateString !== null) {
      // Parse the given date string into a Date object
      var targetDate = new Date(dateString);

      // Get the current date
      var currentDate = new Date();

      // Calculate the difference in milliseconds between the target date and the current date
      var differenceMs = targetDate - currentDate;

      // Convert milliseconds to days
      var daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
      // console.log(daysLeft)
      if (daysLeft < 0) return 0
      else return daysLeft
    } else return 'N/A'
  }

  const checkPlanName = (planName) => {
    let plan = planName.toLowerCase();
    if(plan === "gtxpremium") return 'Premium (Intro)'.toUpperCase()
    else if (plan === "gtxultimate") return 'Ultimate (Intro)'.toUpperCase()
    else if (plan === "premium") return 'Premium (Advanced)'.toUpperCase()
    else if (plan === "ultimate") return 'Ultimate (Advanced)'.toUpperCase()
    else return planName.toUpperCase()
  }

  // const upcomingPlanStartDate = (renewDate, number) => {
  //   renewDate = new Date(renewDate);
  //   renewDate.setDate(renewDate.getDate() + (30 * number))
  //   renewDate.setHours(renewDate.getHours() - 5);
  //   renewDate.setMinutes(renewDate.getMinutes() - 30);
  //   renewDate = new Date(renewDate);
  //   const monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   let month = monthNames[renewDate.getMonth()];
  //   return renewDate.getDate() + " " + month + " " + renewDate.getFullYear();
  // };

  // const returnUpcomingPlans = () => {
  //   let plan = null;
  //   if (user) {
  //     if (user.upcomingPlans)
  //       if (user.upcomingPlans[0].plan == "premium")
  //         plan = "pro";
  //       else if (user.upcomingPlans[0].plan == "unlimited")
  //         plan = "ultimate";
  //       else if (user.upcomingPlans[0].plan == "extreme")
  //         plan = "unlimited";
  //   }
  //   return plan;
  // }

  // const returnPlan = () => {
  //   let plan = "";
  //   if (profile) {
  //     if (profile.plan == "basic" && profile.totalTimeMonth)
  //       plan = "trial"
  //     else if (profile.plan == "premium")
  //       plan = "pro";
  //     else if (profile.plan == "unlimited")
  //       plan = "ultimate";
  //     else if (profile.plan == "extreme")
  //       plan = "unlimited";
  //     else
  //       plan = profile.plan;
  //   }
  //   return plan;
  // }

  // const timeLeft = () => {
  //   let plan = "";
  //   if (profile) {
  //     if (profile.plan != "extreme") {
  //       let hoursLeft = Math.floor(
  //         (profile.totalTimeMonth - profile.timePlayedMonth) / 60
  //       );
  //       let minutesLeft = (profile.totalTimeMonth - profile.timePlayedMonth) % 60;
  //       if (profile.totalTimeMonth - profile.timePlayedMonth < 0) {
  //         hoursLeft = 0;
  //         minutesLeft = 0;
  //       }
  //       renewDate = getDateString(profile.renewDate);
  //       plan = hoursLeft + " hrs " + minutesLeft + " mins";
  //     } else {
  //       renewDate = getDateString(profile.renewDate);
  //       plan = "Unlimited";
  //     }
  //   }
  //   return plan;
  // };
  
  if (loading) {
    return <Spinner />
  }

  return (
    <div className={style.billing}>
      <h3>Billing and Membership</h3>
      <div className={style.billing_plan_details}>
        <FormInput
          label="Type"
          value={checkPlanName(user.currentPlan)}
          disabled={true}
        />
        {user.currentPlan == "basic" || user.currentPlan == "Basic" ? <div /> : <FormInput label="Time Left" value={timeLeft()} disabled={true} />}
        {/* {returnUpcomingPlans()?<FormInput label="Next Plan" value={returnUpcomingPlans().toUpperCase()} disabled={true} />:<div />} */}
        {(user.currentPlan == "basic" || user.currentPlan == "Basic") && !user.renewDate ? <div /> : <FormInput label={user.upcomingPlans && user.upcomingPlans.length > 0 ? 'Renewal on' : 'Ends On'} value={user.renewDate ? getDateString(user.renewDate) : 'N/A'} disabled={true} />}
        {(user.currentPlan == "basic" || user.currentPlan == "Basic") && !user.renewDate ? <div /> : <FormInput label="Days left" value={user.renewDate ? daysLeft(user.renewDate) : 'N/A'} disabled={true} />}
        {user && user.upcomingPlans && <FormInput label="Next Plan" value={checkPlanName(user.upcomingPlans[0].planName)} disabled={true} /> }

      </div>

      {/* <h3>Upcoming Plans</h3>
      <div className={style.billing_plan_details}>
        {user.upcomingPlans && user.upcomingPlans.map((plan, index) => {
          return (
            <>
              <FormInput
                label="Type"
                value={plan.planName.toUpperCase()}
                disabled={true}
              />
              <FormInput label="Starts on" value={user.renewDate ? upcomingPlanStartDate(user.renewDate, index) : 'N/A'} disabled={true} />
            </>
          )
        })}

      </div> */}
    </div>
  );
}

export default BillingDetails;
